<template>
  <div class="flexben-menu-items" v-if="hasAccess && userLevel !== null">
    <a class="cursor-pointer"
       :class="{'active-menu-link':isSuperUpRoute, 'mr-2':menuShow, 'text-white mr-3':!menuShow}"
       @click="collapseMenu()"
      >
      {{$t('superUpLang.menu.app_name')}}
      <font-awesome-icon
        icon="chevron-down"
        class="float-right mx-0 mt-1" :class="{
         'btn-collapsed-off':menuShow,
         'btn-collapsed-on':menuShow
       }"/>
    </a>

    <div class="flx-menu-items"
         :id="navListIndex"
         :key="navListIndex"
         :class="{ 'flx-menu-items-show': menuShow }">
      <template v-for="(nevItem, key) of navItems">
        <a :key="navListIndex+key"
           :href="nevItem.link"
           :class="{'active-menu-link':checkMenuActive(nevItem.menu_active_keys)}">
          {{ $t("superUpLang.menu." + nevItem.text_key) }}
        </a>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "SuperUpMenu",
  props: {
    menuSelected: {
      type: Object,
      require: true
    }
  },
  computed: {
    isSuperUpRoute() {
      return (
        this.menuSelected.superUpCategoriesList||
        this.menuSelected.superUpLabelsList||
        this.menuSelected.superUpTreeList||
        this.menuSelected.superUpTreeNew||
        this.menuSelected.superUpTreeUpdate
      );
    },
    navItems() {
      const navItems = {
        admin: [
          {
            link: this.$router.resolve({name: 'superUp_categories_list'}).href,
            text_key: 'categoriesList',
            menu_active_keys: ['superUpCategoriesList'],
            module_permission_code: null
          },
          {
            link: this.$router.resolve({name: 'superUp_labels_list'}).href,
            text_key: 'labelsList',
            menu_active_keys: ['superUpLabelsList'],
            module_permission_code: null
          },
          {
            link: this.$router.resolve({name: 'superUp_tree_list'}).href,
            text_key: 'treeList',
            menu_active_keys: ['superUpTreeList'],
            module_permission_code: null
          }
        ],
      };
      let returnData = [];
      for (let nevItem of navItems[this.userLevel]) {
        if (this.checkModulePermission(nevItem.module_permission_code)) {
          returnData.push(nevItem);
        }
      }
      return returnData;
    }
  },
  data() {
    return {
      flxCookie: null,
      userLevel: null,
      hasAccess: false,
      menuShow: true,
      permission: {},
      navListIndex: 0,
      active_employment: {}
    };
  },
  created() {
    this.permission = this.flxUserLevel();
    this.hasAccess = this.permission.hasAccess;
    this.userLevel = this.permission.userLevel;
    this.menuShow = this.checkAndShowCollapsedMenu();
    this.setActiveEmployment();
  },
  methods: {
    checkAndShowCollapsedMenu() {
      if (this.isSuperUpRoute) {
        localStorage.setItem('flexbenMenuCollapsed', false);
      }
      return this.isSuperUpRoute
    },

    collapseMenu() {
      this.menuShow = !this.menuShow;
    },
    checkMenuActive(menuActiveKeys) {
      for (let key of menuActiveKeys) {
        if (this.menuSelected[key]) {
          return true;
        }
      }
      return false;
    },
    checkModulePermission(toCheck) {
      if (toCheck == null && this.userLevel === 'admin') {
        return true;
      }
      if (this.active_employment == null) {
        return false;
      }
      return this.checkFlxModulePermission(this.active_employment.module_permissions, toCheck);
    },
    setActiveEmployment() {
      this.active_employment = this.$store.getters.flx_activeEmployment;
      this.navListIndex += 1;
    },
  }
};
</script>
<style scoped src="@/assets/styles/beneficiary.css"></style>
<style scoped lang="scss">
.menu-items a {
  display: block;
  color: #fff;
  padding-left: 30px;
  text-decoration: none;
  margin-top: 25px;
  font-size: 16px;
  text-align: left;
}

.menu-items a:hover {
  color: #f79000 !important;
}

.menu-items .flexben-menu-items a {
  padding-left: 50px;
}

a.active-menu-link {
  color: #f79000;
}

.flexben-menu-items {
  .btn-collapsed-off {
    transform: rotate(0deg);
    transition: transform 200ms ease-in;
  }

  .btn-collapsed-on {
    transform: rotate(180deg);
    transition: transform 300ms ease-in;
  }

  .flx-menu-items {
    max-height: 0;
    overflow: hidden;
    height: auto;
    -moz-transition: max-height 300ms ease, height 300ms ease;
    -webkit-transition: max-height 300ms ease, height 300ms ease;
    -o-transition: max-height 300ms ease, height 300ms ease;
    transition: max-height 300ms ease, height 300ms ease;
  }

  .flx-menu-items-show {
    max-height: 2000px;
  }
}
</style>

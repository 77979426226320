<template>
  <div>
    <b-modal data-backdrop="static"
             :hide-footer="true"
             id="invoice-voucher-detail-modal"
             ref="invoice-voucher-detail-modal"
             size="xl"
             :style="'z-index: 2500'"
             headerClass="order-detail-header-modal pb-0"
             contentClass="flexben-container order-detail-modal"
             bodyClass="order-detail-modal"
             centered>

      <div slot="modal-header">
        <div class="row">
          <div class="col-12 d-flex justify-content-between align-items-center text-36">
            <span>{{ $t("flexben.voucher_stocks.invoicesModalFields.modalTitle", { partner: this.invoiceDetails.partner_name, invoice_number: this.invoiceDetails.purchase_invoice_number }) }}</span>
            <button type="button" aria-label="Close" class="close text-danger ml-auto" @click="closeModal">×</button>
          </div>
        </div>
      </div>

      <template v-slot:default="{ hide }">
        <div>
          <div class="container-bg-white mb-3">
            <div class="row p-3">
              <div class="col-12 text-18 font-weight-bold">
                {{$t("flexben.voucher_stocks.invoicesModalFields.invoice_details")}}
              </div>
              <div class="col-12 container-gray rounded-0 mt-3 p-3">
                <div class="row mb-2">
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_without_discount") }}:
                    </span>
                    {{invoiceDetails.invoice_value_without_discount ? invoiceDetails.invoice_value_without_discount : '-' }} Lei
                  </div>
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.invoice_value_with_discount")}}:
                    </span>
                    {{invoiceDetails.invoice_value_with_discount ? invoiceDetails.invoice_value_with_discount : '-' }} Lei
                  </div>
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.discount_value")}}:
                    </span>
                    {{invoiceDetails.discount_value ? invoiceDetails.discount_value : '-' }} Lei
                  </div>
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.discount_percentage")}}:
                    </span>
                    {{invoiceDetails.discount_percentage ? invoiceDetails.discount_percentage : '-' }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.vat_percentage")}}:
                    </span>
                    {{invoiceDetails.vat_percentage ? invoiceDetails.vat_percentage : '-' }}
                  </div>
                  <div class="col-12 col-md-3 d-flex align-items-center mb-2">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.up_purchase_date")}}:
                    </span>
                    {{invoiceDetails.up_purchase_date ? invoiceDetails.up_purchase_date : '-' }}
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12 col-md-6 d-flex align-items-center">
                    <span class="font-weight-bold mr-2">
                        {{$t("flexben.voucher_stocks.invoicesFields.comments")}}:
                    </span>
                    {{invoiceDetails.comments ? invoiceDetails.comments : '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="container-bg-white">
            <div class="row px-3 pt-3">
              <div class="col-12 col-md-6">
                <h3 class="p-0 mt-2 text-capitalize">{{$t("flexben.voucher_stocks.invoicesModalFields.vouchers")}}</h3>
              </div>
              <div class="col-12 col-md-6">
                <input type="text" placeholder="Căutare" v-model="searchQuery"
                       class="search-input float-right m-0"/>
              </div>
              <div class="col-12 table-order-request">
                <div class="table-responsive mt-3 hover-table">
                  <table class="table table-striped">
                    <thead>
                    <tr>
                      <td>{{$t("flexben.voucher_stocks.invoicesFields.code")}}</td>
                      <td>{{$t("flexben.voucher_stocks.invoicesFields.voucher_value")}}</td>
                      <td>{{$t("flexben.voucher_stocks.invoicesFields.reconciled")}}</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(voucher, voucherKey) in filteredVouchers" :key="voucherKey" :class="voucherKey % 2 === 0 ? 'gri-cell' : ''">
                      <td>{{ voucher.code }}</td>
                      <td>{{ voucher.voucher_value }} lei</td>
                      <td>
                        <span :class="['dot', voucherClass(voucher)]"></span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben";

export default {
  name: 'BenefitInvoicesDetailsModal',
  data() {
    return {
      searchQuery: '',
      httpService: httpFlexbenService.benefitVoucherInvoicesStocks,
      invoiceDetails: {
        partner_name: null,
        purchase_invoice_number: null,
        invoice_value_without_discount: null,
        discount_value: null,
        invoice_value_with_discount: null,
        discount_percentage: null,
        vat_percentage: null,
        comments: null,
        up_purchase_date: null,
        vouchers: [],
        pagination: {
          current_page: 1,
          from: 1,
          to: 1,
          per_page: 20,
          last_page: 1,
          total: 0
        }
      },
    };
  },
  methods: {
    closeModal() {
      this.$refs['invoice-voucher-detail-modal'].hide();
    },
    showInvoiceDetails(purchaseInvoiceNumber, partnerCui) {
      this.loading = true;
      this.searchQuery = '';
      this.httpService.invoiceDetails(purchaseInvoiceNumber,partnerCui)
        .then((response) => {
          return response.data;
        })
        .then((data) => {
          this.invoiceDetails = {
            partner_name: data.invoiceDetails.data.partner_name,
            purchase_invoice_number: data.invoiceDetails.data.purchase_invoice_number,
            invoice_value_without_discount: data.invoiceDetails.data.invoice_value_without_discount,
            discount_value: data.invoiceDetails.data.discount_value,
            invoice_value_with_discount: data.invoiceDetails.data.invoice_value_with_discount,
            discount_percentage: data.invoiceDetails.data.discount_percentage,
            vat_percentage: data.invoiceDetails.data.vat_percentage,
            comments: data.invoiceDetails.data.comments,
            up_purchase_date: data.invoiceDetails.data.up_purchase_date,
            vouchers: data.invoiceDetails.data.vouchers,

            pagination: {
              current_page: data.invoiceDetails.current_page,
              from: data.invoiceDetails.from,
              to: data.invoiceDetails.to,
              per_page: data.invoiceDetails.perPage,
              last_page: data.invoiceDetails.lastPage,
              total: data.invoiceDetails.total
            }
          }
          this.$refs['invoice-voucher-detail-modal'].show();
          this.loading = false;
        })
        .catch((error) => {
          this.loaderError = true;
          this.loading = false;
        });

      // this.$refs['invoice-voucher-detail-modal'].show();
    },
  },
  computed: {
    filteredVouchers() {
      if (this.searchQuery === '') {
        return this.invoiceDetails.vouchers;
      }
      return this.invoiceDetails.vouchers.filter(voucher => {
        return voucher.code.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
    voucherClass() {
      return (voucher) => {
        if (voucher.reconciliation_status === 1) {
          return 'green';
        } else if (voucher.reconciliation_status === 2 || voucher.reconciliation_status === null) {
          return 'red';
        }
        return '';
      };
    },
  },
};
</script>

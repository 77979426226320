<template>
  <div class="">
    <div v-if="!this.initialLoading" v-show="componentAction === 'listing'">
      <invoicesfilters :filter-options="filterOptions"
                       :filters="filters"
                       :filterForImport=false
                       :statusTransPatch="'flexben.voucher_stocks.reconciliation_status.'">
        <template v-slot:summary_bottom>
          <div class="row mb-1 px-3">
            <div class="col-12 col-md-12 pr-0 mb-2 mr-3">
              <div class="d-flex justify-content-end flex-column flex-md-row">
                <a class="btn btn-themed px-4 mr-3 mt-2" :class="{'disabled':invoices.data.length===0}"
                   :href="exportUrl"
                   target="_blank"
                   download
                   @click="(event)=>{if(invoices.data.length===0) {event.preventDefault();}}">
                  {{$t("flexben.general.btn.export")}}
                </a>
                <button class="btn btn-themed px-4 mr-2 mt-2"
                        @click="startImport()">
                  {{$t("flexben.voucher_stocks.btns.import_start")}}
                </button>
              </div>
            </div>
          </div>
        </template>
      </invoicesfilters>
      <div class="container-bg-white mt-3 p-3">
        <div class="row">
          <div class="col-12 col-md-6">
            <h2 class="pl-0 mt-0">{{ $t("flexben.voucher_stocks.titles.invoices") }}</h2>
          </div>
          <div class="col-12 col-md-6">
            <input type="text"
                   v-model.trim="searchString"
                   v-on:keyup.enter="search()"
                   :placeholder="$t('flexben.general.search')"
                   class="formedituser search-input float-right"/>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="table-responsive hover-table">
              <table class="table table-striped">
                <thead>
                <tr>
                  <td></td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.partner") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_number") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_without_discount") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.discount_value") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.invoice_value_with_discount") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.discount_percentage") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.vat_percentage") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.comments") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.up_purchase_date") }}</td>
                  <td>{{ $t("flexben.voucher_stocks.invoicesFields.reconciled") }}</td>
                  <td></td>
                </tr>
                </thead>
                <tbody>
                <template v-if="invoices.data.length > 0">
                  <tr v-for="(item, index) of invoices.data" :key="item.id" class="trhover parent">
                    <td>
                      <button
                          class="btn btn-themed-red ml-auto px-2 small-delete-button" @click="showDeleteInvoiceModal(item.purchase_invoice_number, item.partner_cui, item.partner_name)">{{
                        $t('flexben.general.btn.delete') }}
                      </button>
                    </td>
                    <td>{{ item.partner_name }}</td>
                    <td>{{ item.purchase_invoice_number }}</td>
                    <td>{{ item.invoice_value_without_discount ? item.invoice_value_without_discount : '-' }}</td>
                    <td>{{ item.discount_value ? item.discount_value : '-'}}</td>
                    <td>{{ item.invoice_value_with_discount ? item.invoice_value_with_discount : '-' }}</td>
                    <td>{{ item.discount_percentage ? item.discount_percentage : '-'}}</td>
                    <td>{{ item.vat_percentage ? item.vat_percentage : '-' }}</td>
                    <td>{{ item.comments ? item.comments : '-' }}</td>
                    <td>{{ item.up_purchase_date }}</td>
                    <td>
                      <span :class="['dot', voucherStatus(item)]"></span>
                    </td>
                    <td>
                      <button class="btn action-button" @click="openInvoiceDetails(item.purchase_invoice_number, item.partner_cui)">
                        {{$t("flexben.general.btn.see_more")}}
                      </button>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="trhover gri-cell">
                    <td colspan="100" class="text-center">{{ $t("flexben.general.no_results_found") }}
                    </td>
                  </tr>
                </template>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12 p-0">
            <listPagination
                :activePage="invoices.current_page"
                :currentPage="invoices.current_page"
                :totalItems="invoices.total"
                :startItem="invoices.from"
                :endItem="invoices.to"
                :itemsPerPage="invoices.perPage"
                @pageChanged="pageChanged"
                @changePerPage="changePerPage"></listPagination>
          </div>
        </div>
      </div>
    </div>
    <import-benefit-invoices-steps v-if=" componentAction==='import'"
                                   @backToListing="backToListing"
                                   @toggleLoading="toggleLoading"
    ></import-benefit-invoices-steps>
    <benefit-invoices-details-modal ref="benefit_invoices_details_modal"
    ></benefit-invoices-details-modal>
    <delete-invoice-modal
    ref="delete-invoice-modal"
    @closeModal="closeDeleteInvoiceModal"
    ></delete-invoice-modal>
    <content-loader :loader-visible="loading" :loader-error="loaderError"></content-loader>
  </div>
</template>

<script>
import httpFlexbenService from "@/http/http-flexben.js";
import ImportVoucherInvoicesSteps from "./ImportVoucherInvoicesSteps";
import BenefitInvoicesDetailsModal
  from "./modals/BenefitInvoicesDetailsModal.vue";
import InvoicesFilters from "@/components/flexben/benefit_voucher_stocks/partials/InvoicesFilters.vue";
import DeleteInvoiceModal from "@/components/flexben/benefit_voucher_stocks/modals/DeleteInvoiceModal.vue";

export default {
  name: "VoucherInvoicesList",
  components: {
    'invoicesfilters': InvoicesFilters,
    'import-benefit-invoices-steps': ImportVoucherInvoicesSteps,
    'benefit-invoices-details-modal': BenefitInvoicesDetailsModal,
    'delete-invoice-modal': DeleteInvoiceModal
  },
  data: () => {
    return {
      checkedItems: [],
      initialLoading: true,
      loading: true,
      loaderError: false,
      httpService: httpFlexbenService.benefitVoucherInvoicesStocks,
      filterOptions: {
        partners: [],
        reconciliation_status: [],
        status: [],
        purchaseDateFrom: null,
        purchaseDateTo: null,
      },
      filters: {
        partner: [],
        reconciliation_status: [],
        purchaseDateFrom: null,
        purchaseDateTo: null,
        perPage: 20,
        page: 1
      },
      searchString: '',
      filterWithExtra: true,
      invoices: {
        data: [],
        current_page: 1,
        to: 1,
        perPage: 20,
        lastPage: 1,
        total: 1,
      },
      componentAction: 'listing',
      exportUrl: ''
    };
  },
  watch: {
    filters: {
      handler: function (val) {
        this._loadList();
      },
      deep: true
    }
  },
  created() {
    const permissions = this.flxUserLevel();
    if (!permissions.hasAccess || permissions.userLevel !== 'admin') {
      window.location.href = '/company';
      return;
    }
    this._loadList();
  },
  methods: {
    search() {
      this.filters.search = this.searchString;
      this._loadList(true);
    },
    changePerPage(perPage) {
      this.filters.page = 1;
      this.filters.perPage = perPage;
      this._loadList();
    },
    pageChanged(pageNb) {
      this.filters.page = pageNb;
      this._loadList();
    },
    _loadList(isSearch = false) {
      let filters;
      if (isSearch) {
        filters = {
          search: this.searchString
        };
      } else {
        filters = {...this.filters};
      }
      this.loading = true;
      filters.filterWithExtra = this.filterWithExtra ? 1 : 0;
      this.httpService.list(this.filters)
          .then((response) => {
            return response.data;
          })
          .then((data) => {
            this.invoices = data.list;
            if (this.filterWithExtra) {
              this.filterOptions = data.filters;
              this.filterWithExtra = false;
            }
            if (this.initialLoading) {
              this.initialLoading = false;
            }
            this.loading = false;

            this._buildExportUrl();
          })
          .catch((error) => {
            this.loaderError = true;
            this.loading = true;
          });
    },
    openInvoiceDetails(purchaseInvoiceNumber, partnerCui) {
      this.$refs['benefit_invoices_details_modal'].showInvoiceDetails(purchaseInvoiceNumber, partnerCui);
    },
    showDeleteInvoiceModal(InvoiceNumber, partnerCui, partnerName) {
      this.$refs['delete-invoice-modal'].showConfirmation(InvoiceNumber, partnerCui,partnerName);
    },
    closeDeleteInvoiceModal() {
      this.$refs['delete-invoice-modal'].closeModal();
      this._loadList();
    },
    startImport() {
      this.componentAction = 'import';
    },

    backToListing(withRefresh = false) {
      if (this.componentAction === 'details') {
        this.invoiceDetails = {
          id: null
        };
      }

      if (this.componentAction === 'import') {
        this.invoiceDetails = {
          id: null
        };
      }

      this.componentAction = 'listing';
      if (withRefresh) {
        this.filterWithExtra = true;
        this._loadList();
      }
    },
    toggleLoading(loading, loaderError = false) {
      this.loading = loading;
      this.loaderError = loaderError;
    },
    _buildExportUrl() {
      let url = process.env.VUE_APP_FLEXBEN_BASE + 'benefit-invoice/export?';
      let params = {
        partner: this.filters.partner,
        reconciliation_status: this.filters.reconciliation_status,
        search: this.filters.search,
        purchaseDateFrom:this.filters.purchaseDateFrom,
        purchaseDateTo:this.filters.purchaseDateTo,
      };

      let esc = encodeURIComponent;
      let query = Object.keys(params)
          .filter(k => (params[k] != null))
          .map(k => esc(k) + '=' + esc(params[k]))
          .join('&');
      this.exportUrl = url + query;

    },
    getInvalidReasons(invalidReasons) {
      let errorList = [];
      let indexKey, errorField;
      for (errorField in invalidReasons) {
        if (typeof invalidReasons[errorField] === 'string') {
          errorList.push(this.$t('flexben.formFieldError', {
            field: this.$t('flexben.voucher_stocks.fields.' + errorField),
            reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField])
          }))
        } else {
          for (indexKey in invalidReasons[errorField]) {
            errorList.push(this.$t('flexben.formFieldError', {
              field: this.$t('flexben.voucher_stocks.fields.' + errorField),
              reason: this.$t('flexben.formErrorReasons.' + invalidReasons[errorField][indexKey])
            }))
          }
        }
      }
      return errorList.join('<br>');
    },
  },
  computed: {
    voucherStatus() {
      return (item) => {
        if (item.reconciliation_status === 1) {
          return 'green';
        } else if (item.reconciliation_status === 2 || item.reconciliation_status === null) {
          return 'red';
        }
        return '';
      };
    },
  },
}
</script>
